<template>
  <div>
    <Loader v-if="performingRequest" />
    <div class="dashboard__container--body pt-0 pb-0">
      <div class="dashboard__container--body--col mb-2">
        <div class="slidecontainer whiteBack">
          <div>{{distance}} Miles</div>
          <input type="range" min="1" max="500"  v-model="distance" class="slider" id="myRange" @change="updateDistance()">
        </div>
      </div>
      <div class="dashboard__container--body--col mb-2">
        <div class="slidecontainer whiteBack">
          <div class="mb-3">
            <label for="category">Filter By Skills:</label>
            <v-select
              id="category"
              class="mt-2"
              label="title" 
              :options="skills"
              v-model="filteredSkills"
              :clearable="true"
               multiple
               @input="updateSkills()"
              >
            </v-select>
          </div>
        </div>
      </div>
    </div>

  <div class="pt-0 pb-2" v-if="filteredResults && filteredResults.length">
    <div class="caption ml-3">Results: {{filteredResults.length}}</div>
  </div>

  <div class="pt-0" v-if="filteredResults && filteredResults.length >= 1">
    <div class="dashboard__container--body">

      <UserTable :groupUsers="filteredResults" />
    </div>
  </div>
  </div>
</template>

<style scoped>
  .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #00C897;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #00C897;
  cursor: pointer;
}
</style>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'
import 'instantsearch.css/themes/satellite-min.css'

export default {
  name: 'staffingProximity',
  data() {
    return {
      batch: [],
      newGroup: null,
      showTextBox: false,
      showGroupBox: false,
      textMessage: '',
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
      isShowDistanceFilter: false,
      distance: 100,
      filteredSkills: [],
      place: '',
      lat: '',
      lng: '',
    };
  },

  computed: {
    ...mapState(['eventInfo', 'currentUser', 'userProfile', 'usersByProximity', 'skills', 'groups']),
    filteredResults() {
      if (!this.filteredSkills || this.filteredSkills.length == 0) {
        return this.usersByProximity
      } else if (this.filteredSkills && this.filteredSkills.length >= 1) {

        return this.validUsers.filter(user => this.filteredSkills.some(
        skill => user.skills.some(({title}) => (title === skill.title))));

      } else {
        return []
      }
    },
    validUsers() {
      return this.usersByProximity.filter(user => {
        return ((Array.isArray(user.skills)))
      })
    }
  },
  components: {
    Loader,
    UserTable
  },
  created () {
    this.$store.dispatch("getSkills")
    this.$store.dispatch("getGroups")
  },
  methods: {
    async addToGroup() {
      this.performingRequest3 = true
      let group = this.newGroup
      await this.batch.forEach(user => {
        this.$store.dispatch("addUserToGroup", {
          group: group,
          user: user
        })
      })
      this.performingRequest3 = false
      this.newGroup = null
      this.showGroupBox = false
    },
    async sendTextMessage () {
      this.performingRequest3 = true
      let message = this.textMessage
      await this.batch.forEach(user => {
        console.log(user)
        if (user.phone) {
          let newMessage = {
            userId: user.id,
            phone: user.phone,
            message: message,
          }
          console.log(newMessage)
          this.$store.dispatch('sendUserMessage', newMessage)
        } 
      })
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    showDistanceFilter() {
      this.isShowDistanceFilter = true
    },
    hideDistanceFilter() {
      this.isShowDistanceFilter = false
    },
    removeEntry(index) {
      this.usersByProximity.splice(index, 1)
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    // clearPlace() {
    //   this.$store.dispatch("clearUsersByProximity")
    //   this.currentPlace = null
    //   this.lat = null
    //   this.lng = null
    // },
    // async setPlace(place) {
    //   await this.$store.dispatch("clearUsersByProximity")
    //   this.performingRequest = true
    //   this.lat = null
    //   this.lng = null
    //   this.currentPlace = place
    //     if (this.currentPlace) {
    //       lat = this.eventInfo.event_location._lat,
    //       lng = this.eventInfo.event_location._lat
    //       // this.marker = {position: marker}
    //       // this.place = this.currentPlace
    //       // // this.userProfile.center = marker;
    //       // this.currentPlace = null;
    //     }
    //     // if (place.geometry && place.geometry.viewport && place.geometry.viewport.Ja) {
    //     //   this.lng = place.geometry.viewport.Ja.hi || null
    //     //   this.lat = place.geometry.viewport.Va.hi || null
    //     // }
    //     // if (place.address_components) {
    //     //   this.performingRequest = true
    //     //   this.userProfile.address = {}
    //     //   setTimeout(() => {
    //     //       this.performingRequest = false
    //     //   }, 1000)
    //     //   console.log(this.userProfile.address)
    //     //   var components = place.address_components;
    //     //   for (var i = 0, component; component = components[i]; i++) {
    //     //     if (component.types[0] == 'street_number') {
    //     //         this.userProfile.address.street_number = component['long_name'];
    //     //     }
    //     //     if (component.types[0] == 'route') {
    //     //         this.userProfile.address.street = component['long_name'];
    //     //     }
    //     //     if (component.types[0] == 'locality') {
    //     //         this.userProfile.address.city = component['long_name'];
    //     //     }
    //     //     if (component.types[0] == 'administrative_area_level_1') {
    //     //         this.userProfile.address.state = component['short_name'];
    //     //     }
    //     //     if (component.types[0] == 'country') {
    //     //         this.userProfile.address.country = component['short_name'];
    //     //     }
    //     //     if (component.types[0] == 'postal_code') {
    //     //         this.userProfile.address.zip = component['short_name'];
    //     //     }
    //     //   }
    //     // }

    //   // this.updateProfile()
    //   // this.place = ''
    //   let coords = {
    //     lat: this.eventInfo.event_location._lat,
    //     lng: this.eventInfo.event_location._lat
    //   }
    //   // this.$emit('clicked', this.valid)
    //   this.$store.dispatch("getUsersByProximity", {
    //     coords: coords,
    //     distance: this.distance
    //   })
    // },
    async updateSkills() {
      this.performingRequest = true
      await this.$store.dispatch("clearUsersByProximity")
      let coords = {
        lat: this.eventInfo.event_location._lat,
        lng: this.eventInfo.event_location._long
      }
      this.$store.dispatch("getUsersByProximity", {
        coords: coords,
        skills: this.filteredSkills,
        distance: this.distance
      })
      if (this.usersByProximity && this.usersByProximity.length && this.usersByProximity.length >= 1) {
        this.performingRequest = false
      }
    },
    async updateDistance() {
      this.performingRequest = true
      let coords = {
        lat: this.eventInfo.event_location._lat,
        lng: this.eventInfo.event_location._long
      }
      await this.$store.dispatch("clearUsersByProximity")
      await this.$store.dispatch("getUsersByProximity", {
        coords: coords,
        skills: this.filteredSkills,
        distance: this.distance
      })
      this.performingRequest = false
      
    },
    
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Points",
        "Rating",
        "Skills",
        "City",
        "State"
      ]
      const exportItems = [];
      for (var key in this.batch) {
        exportItems.push([
          this.batch[key].firstName,
          this.batch[key].lastName,
          this.batch[key].email,
          this.batch[key].phone,
          this.batch[key].points,
          this.batch[key].rating,
          this.batch[key].skills,
          this.batch[key].address.city,
          this.batch[key].address.state,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },

    // exportUsers() {
    //   const exportHeaders = [
    //     "Created",
    //     "First Name",
    //     "Middle Name",
    //     "Last Name",
    //     "Phone",
    //     "Email",
    //   ]
    //   const exportItems = [];
    //   for (var key in this.users) {

    //     let created = (this.formatDate(this.users[key].created) || null)

    //     exportItems.push([
    //       created,
    //       this.users[key].firstName,
    //       '',
    //       this.users[key].lastName,
    //       this.users[key].phone,
    //       this.users[key].email,
    //     ])
    //   }
    //   this.$gapi.getGapiClient().then(gapi => {
    //     const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
    //     exportService.export();
    //   });
    // },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.newGroup = ''
    delete this.newGroup
    this.textMessage = ''
    delete this.textMessage
    this.performingRequest = ''
    delete this.performingRequest
    this.performingRequest2 = ''
    delete this.performingRequest2
    this.performingRequest3 = ''
    delete this.performingRequest3
    this.isShowDistanceFilter = ''
    delete this.isShowDistanceFilter
    this.distance = ''
    delete this.distance
    this.filteredSkills = ''
    delete this.filteredSkills
    this.searchClient = ''
    delete this.searchClient
    this.batch = ''
    delete this.batch
    this.showGroupBox = ''
    delete this.showGroupBox
    this.showTextBox = ''
    delete this.showTextBox
    this.place = ''
    delete this.place
    this.lat = ''
    delete this.lat
    this.lng = ''
    delete this.lng
    this.columns = ''
    delete this.columns
    this.$store.dispatch('clearGroups')
    this.$store.dispatch('clearSkills')
    this.$store.dispatch("clearUsersByProximity")
  }
}
</script>